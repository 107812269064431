import {
	getCurrentCompany,
	getUserId,
} from "../../../../services/storage-window";

export default {
	GET_ARTICLES(state) {
		return state.articles;
	},
	GET_COMPANY_SETTINGS(state) {
		return state.companySettings;
	},
	GET_USER_SETTINGS(state) {
		return state.userSettings;
	},
	GET_USER_ID(state) {
		return getUserId();
	},
	GET_USER_SETTINGS_HIDE_FIELD_STATUS(state) {
		return state.hideFieldStatus;
	},
	GET_CONNECTED_SHOP_LIST(state) {
		return state.connectedShopList;
	},
	GET_NON_CONNECTED_SHOP_LIST(state) {
		return state.nonConnectedShopList;
	},
	GET_BILLING_INFO(state) {
		return state.billing;
	},
	GET_SHOP_USERS(state) {
		return state.shopUsers;
	},
	GET_APPLICATION_LOGS(state) {
		return state.applicationLog;
	},
	GET_BANK_ID(state) {
		return state.bankID;
	},
	GET_SUBCRIPTION_TYPE(state) {
		return state.subscriptionType;
	},
	GET_INTEGRATION_METRICS(state) {
		return state.integrationMetrics;
	},
	GET_CONNECTED_WEBSHOP(state) {
		return state.connectedWebshop;
	},
	GET_USER_SUBSCRIPTION(state) {
		return state.userSubscription;
	},
	GET_COUNTRY(state) {
		return state.country;
	},
	GET_CUSTOMER_NAME(state) {
		return state.customerName;
	},
	GET_WEBSHOP_PROVIDER(state) {
		return state.webshopProviders;
	},
	GET_WEBSHOP_PRODUCTS(state) {
		return state.webshopProduct;
	},
	CART_PRODUCT(state) {
		return state.cartProduct;
	},
	GET_CATEGORY(state) {
		return state.category.data;
	},
	CART_AMOUNT(state) {
		let amount = 0;
		if (state.cartProduct.length > 0) {
			state.cartProduct.forEach((element) => {
				// if (element.hasOwnProperty('apply_discount')) {
				// 	if (element.apply_discount === true) {
				// 		let price = !parseFloat(element.discounted_price) ? 1 : element.discounted_price
				// 		amount += element.qty * price
				// 	} else {
				// 		let price = !parseFloat(element.sales_price) ? 1 : element.sales_price
				// 		if (parseFloat(price) === 0) {
				// 			price = 1
				// 		}
				// 		amount += element.qty * price
				// 	}
				// } else {
				// 	let price = !parseFloat(element.sales_price) ? 1 : element.sales_price
				// 	if (parseFloat(price) === 0) {
				// 		price = 1
				// 	}
				// 	amount += element.qty * price
				// }
				const price = !element.has_discount
					? parseFloat(element?.sales_price)
					: parseFloat(element.discounted_price);
				amount += element.qty * price;
			});
			return parseFloat(amount).toFixed(2);
		} else {
			return parseFloat(amount).toFixed(2);
		}
	},
	CART_AMOUNT_INC_VAT(state) {
		let amount = 0;
		if (state.cartProduct.length > 0) {
			state.cartProduct.forEach((element) => {
	
				amount = parseFloat(amount) + parseFloat(element.priceIncVat);
			});

			return parseFloat(amount).toFixed(2);
		} else {
			return parseFloat(amount).toFixed(2);
		}
	},
	CART_INC_VAT_AMOUNT(state) {
		let amount_vat = 0;
		if (state.cartProduct.length > 0) {
			state.cartProduct.forEach((element) => {
				let vat_rate = parseFloat(`1.${element.vat_rate}`);
				let price = !parseFloat(element.discounted_price)
					? 1
					: element.discounted_price;
				let dt =
					parseFloat(element.qty) *
					parseFloat(price) *
					parseFloat(vat_rate);
				amount_vat += dt;
			});
			return parseFloat(amount_vat).toFixed(2);
		} else {
			return parseFloat(amount_vat).toFixed(2);
		}
	},
	CART_NUMBER(state) {
		let number = 0;
		if (state.cartProduct.length > 0) {
			state.cartProduct.map((element) => {
				number += element.qty;
			});
		}
		return number;
	},
	GET_BUYER_TOUR(state) {
		return state.buyerTour;
	},
	GET_BLOG_CATEGORY(state) {
		return state.blogCategory;
	},
	GET_BLOG_POST(state) {
		return state.blogPost;
	},
	GET_SINGLE_BLOG(state) {
		return state.singleBlogPost;
	},
	GET_ARCHIVES(state) {
		return state.archives;
	},
	GET_FAQ(state) {
		return state.faqPost;
	},
	GET_FAQ_CATEGORY(state) {
		return state.faqPostCategory;
	},
	GET_COMPANY_CHECKOUT_CONFIG(state) {
		return state.companyCheckoutConfig;
	},
	GET_SINGLE_ARTICLE(state) {
		return state.singleArticle;
	},
	GET_ARTICLE_SPECIFICATION(state) {
		return state.articleSpecification;
	},
	GET_ARTICLE_VARIATIONS(state) {
		return state.articleVariations;
	},
	GET_ARTICLE_PARAMS(state) {
		return state.articleParams;
	},
	GET_DELIVERY_ADDRESS(state) {
		return state.deliveryAddress;
	},
	GET_END_CUSTOMER(state) {
		return state.END_CUSTOMER;
	},
	GET_CURRENT_COMPANY(state) {
		return state.currentCompany;
	},
	GET_NOTIFICATION(state) {
		return state.userNotification;
	},
	GET_ALL_NOTIFICATION(state) {
		return state.allNotification;
	},
};
