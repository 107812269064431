<template>
	<div class="app-header header-shadow roboto-font">
		<div
			class="app-header__logo nav-header-side"
			style="background: #181c4c"
		>
			<div class="logo-sr"></div>
			<div class="header__pane ml-auto"></div>
		</div>
		<div class="app-header__mobile-menu">
			<div>
				<button
					type="button"
					id="hamburger-btn"
					@click="toggleSidebar"
					class="hamburger hamburger--elastic mobile-toggle-nav"
				>
					<span class="hamburger-box">
						<span class="hamburger-inner"></span>
					</span>
				</button>
			</div>
		</div>
		<div class="app-header__menu">
			<span>
				<button
					type="button"
					id="top-wrapper"
					class="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav"
					@click="toggleNavTop"
				>
					<span class="btn-icon-wrapper">
						<i class="fa fa-ellipsis-v fa-w-6"></i>
					</span>
				</button>
			</span>
		</div>
		<div class="app-header__content" id="div-to-open">
			<div class="app-header-left">
				<ul class="header-megamenu nav"></ul>
			</div>
			<div class="app-header-right">
				<div class="header-dots">
					<div class="dropdown-side-top dropdown md:w-32">
						<button
							data-cy="lang-btn"
							type="button"
							data-toggle="dropdown"
							class="md:ml-4 flex w-full justify-between align-items-center space-x-5 p-0 mr-2 btn btn-lin v-step-1"
						>
							<div class="flex align-items-center space-x-4">
								<span>
									<img
										v-if="GET_LOCALE_LANG === 'en'"
										class="h-7 w-7 rounded-full"
										src="./../../../assets/images/flags/gb.svg"
										alt="flags"
									/>
									<img
										v-else
										class="h-7 w-7 rounded-full"
										style="max-width: unset !important"
										src="./../../../assets/images/flags/se.svg"
										alt="flags"
									/>
								</span>
								<span
									data-cy="chosen-lang"
									class="no-deco ml-2 tracking-wide font-semibold"
								>
									<span v-if="GET_LOCALE_LANG === 'en'"
										>English</span
									>
									<span v-else>Swedish</span>
								</span>
							</div>
							<i
								class="icon ion-ios-arrow-down ml-2 opacity-8"
							></i>
						</button>
						<div
							tabindex="-1"
							role="menu"
							aria-hidden="true"
							class="rm-pointers dropdown-menu dropdown-menu-right"
						>
							<div class="dropdown-menu-header"></div>

							<button
								data-cy="btn-lang-eng"
								type="button"
								@click="changeLocale('en')"
								tabindex="0"
								class="dropdown-item flex align-items-center"
							>
								<img
									class="h-7 w-7 mr-2"
									src="./../../../assets/images/flags/4x3/GB.svg"
									alt="flags"
								/>
								English
							</button>
							<button
								data-cy="btn-lang-se"
								type="button"
								@click="changeLocale('se')"
								tabindex="0"
								class="dropdown-item flex align-items-center"
							>
								<img
									class="h-7 w-7 mr-2"
									src="./../../../assets/images/flags/4x3/SE.svg"
									alt="flags"
								/>
								Swedish
							</button>
							<button
								data-cy="btn-lang-se"
								type="button"
								@click="changeLocale('se')"
								tabindex="0"
								class="dropdown-item flex align-items-center"
							>
								<img
									class="h-7 w-7 mr-2"
									src="./../../../assets/images/flags/4x3/DK.svg"
									alt="flags"
								/>
								Danish
							</button>
							<button
								data-cy="btn-lang-se"
								type="button"
								@click="changeLocale('se')"
								tabindex="0"
								class="dropdown-item flex align-items-center"
							>
								<!-- <span class="mr-3 opacity-8 flag small SE"></span> -->
								<img
									class="h-7 w-7 mr-2"
									src="./../../../assets/images/flags/4x3/NO.svg"
									alt="flags"
								/>
								Norwegian
							</button>
						</div>
					</div>
				</div>
				<div class="header-btn-lg pr-0">
					<div class="widget-content p-0">
						<div class="widget-content-wrapper">
							<div class="widget-content-left">
								<div class="btn-group">
									<a
										data-cy="user-company"
										data-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false"
										class="p-0 btn d-flex align-items-center"
									>
										<p
											class="h-10 w-10 bg-gray-300 animate-pulse rounded-full"
											v-if="loadingAuth === true"
										></p>
										<div
											class="flex justify-center align-items-center no-profile-img"
											:style="'background:' + rcolor"
											v-if="loadingAuth === false"
										>
											<div>
												<span
													class="font-black"
													v-if="
														GET_USER.first_name !==
														undefined
													"
												>
													{{
														GET_USER.first_name
															| initialNames
													}}
												</span>
											</div>
										</div>

										<p
											class="bg-gray-300 w-40 h-4 rounded ml-2 animate-pulse"
											v-if="loadingAuth === true"
										></p>
										<div
											class="header-user-name ml-2 hidden md:block link-theme-color font-semibold"
											v-else
										>
											<span
												v-if="
													GET_USER.first_name !==
													undefined
												"
												>{{
													fullName
														| capitalizeEachString
												}}</span
											>
										</div>
										<i
											class="icon ion-ios-arrow-down ml-2 opacity-8"
										></i>
									</a>
									<div
										data-cy="company-panel"
										tabindex="-1"
										role="menu"
										aria-hidden="true"
										class="rm-pointers tweak-style dropdown-menu-lg dropdown-menu dropdown-menu-right"
									>
										<div
											class="scroll-area-xs override-height"
										>
											<!--style="height: 150px;"-->
											<div class="scrollbar-container ps">
												<ul class="nav flex-column">
													<li class="nav-item">
														<a
															href="javascript:void(0);"
															@click="logoutUser"
															class="text-sm link-theme-color nav-link"
														>
															<span
																class="text-xl icon-color mr-2 mt-1 mb-1 iconly-brokenLogout"
															></span>
															{{ $t("logout") }}
														</a>
													</li>
												</ul>
											</div>
										</div>
										<ul class="nav flex-column">
											<li
												class="nav-item-divider nav-item"
											></li>
											<li
												class="nav-item-btn text-center nav-item"
											>
												Version 1.2.0
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import bus from "../../../bus";
import i18n from "@/plugins/i18n";
import { clearCartItem } from "../../../services/storage-window";

export default {
	name: "NavBar",
	data() {
		return {
			baseURL: window.location.origin,
			user: {},
			authUser: {},
			notification: [],
			ownerCompany: null,
			notifyOpen: false,
			company: [],
			notify_count: 0,
			unread_count: 0,
			rcolor: window.localStorage.getItem("randomc") || "#000000",
			unread_notify: false,
			loadingCompany: true,
			loadingAuth: true,
			fullName: "",
			// menuStyles : menuStyles
		};
	},
	watch: {
		notifyOpen(newVal, oldVal) {
			window.Bus.$emit("show-cover", this.notifyOpen);
		},
	},
	computed: {
		// menuStyleUpdate : function () {
		//     return menuStyles
		// },
		...mapGetters({
			USER_TOKEN: "auth/USER_TOKEN",
			GET_COMPANY_USER: "portal/GET_COMPANY_USER",
			GET_NOTIFICATION: "portal/GET_NOTIFICATION",
			GET_LOCALE_LANG: "utility/GET_LOCALE_LANG",
			GET_CUSTOMIZATION: "portal/GET_CUSTOMIZATION",
			GET_USER: "administrator/GET_AUTH_USER",
		}),
	},
	mounted() {
		this.getAuthUser();
		window.Bus.$on("close-cart", (e) => {
			this.notifyOpen = false;
		});
	},
	methods: {
		getAuthUser() {
			this.loadingAuth = true;
			this.$store
				.dispatch("administrator/getAuthUser")
				.then((_) => {
					this.loadingAuth = false;
					this.fullName =
						this.GET_USER.first_name +
						" " +
						this.GET_USER.last_name;
				})
				.catch((err) => {
					this.loadingAuth = false;
					if (err.status === 401) {
						this.$store.commit("auth/CLEAR_AUTH_USER", null);
						window.Bus.$emit("sign-out");
					}
				});
		},
		getUserNotification() {
			this.$store.dispatch("portal/getUserNotification");
		},
		changeLocale(locale) {
			i18n.locale = locale;
			this.$services.moment.locale(locale);
			this.$store.commit("utility/CHANGE_LOCALE", locale);
		},
		openNotifyDiv() {
			this.notifyOpen = !this.notifyOpen;
		},
		handleScroll(e) {
			if (parseInt(e.target.scrollTop) > 60) {
				document
					.getElementById("remove-div")
					.classList.add("hide-transition-height");
				document
					.getElementById("extend-height")
					.classList.add("notify-height");
			} else {
				document
					.getElementById("remove-div")
					.classList.remove("hide-transition-height");
				document
					.getElementById("extend-height")
					.classList.remove("notify-height");
			}
		},
		toggleSidebar() {
			document
				.getElementById("hamburger-btn")
				.classList.toggle("is-active");
			document
				.getElementById("app-root-container")
				.classList.toggle("sidebar-mobile-open");
		},
		toggleNavTop() {
			document
				.getElementById("div-to-open")
				.classList.toggle("header-mobile-open");
		},
		logoutUser() {
			this.$store.commit("customer/EMPTY_CART", null);
			this.changeLocale("se");
			this.$store.commit("auth/CLEAR_AUTH_USER", null);
			this.$router.replace({ name: "login" });
		},
	},
};
</script>

<style scoped>
.app-header__logo {
	width: 200px !important;
}
</style>
